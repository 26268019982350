/* Vessel tracking list */
.VesselTrackingList .child-data-table .rt-thead .rt-th:first-child,
.VesselTrackingList .child-data-table .rt-tbody .rt-td:first-child {
    display: none;
} 

/* MBL tracking list */
.MBLTrackingList .child-data-table .rt-thead .rt-th:first-child,
.MBLTrackingList .child-data-table .rt-tbody .rt-td:first-child {
    display: none;
}

/* Delivery and Return equipment list */
.DnREquipmentList .child-data-table .rt-thead .rt-th:first-child,
.DnREquipmentList .child-data-table .rt-tbody .rt-td:first-child {
    display: none;
}

/* Air delivery list */
.DnRAirDeliveryList .child-data-table .rt-thead .rt-th:first-child,
.DnRAirDeliveryList .child-data-table .rt-tbody .rt-td:first-child {
    display: none;
}

/* HAWB planning list */
.HAWBPlanningTable .child-data-table .rt-thead .rt-th:first-child,
.HAWBPlanningTable .child-data-table .rt-tbody .rt-td:first-child {
    display: none;
}

/* CBL release list */
.CBLReleaseList .child-data-table .rt-thead .rt-th:first-child,
.CBLReleaseList .child-data-table .rt-tbody .rt-td:first-child {
    display: none;
}

/* Ocean clearance list */
.CustomsOceanClearanceList .rt-thead.-header .rt-th:nth-child(2),
.CustomsOceanClearanceList .rt-tbody .rt-tr .rt-td.-checkbox:nth-child(2) {
    display: none;
}

.CustomsOceanClearanceList .child-data-table .rt-thead.-header .rt-th:nth-child(2) {
    display: flex;
}

/* Air clearance list */
.AirClearanceList .rt-thead.-header .rt-th:nth-child(2),
.AirClearanceList .rt-tbody .rt-tr .rt-td.-checkbox:nth-child(2) {
    display: none;
}

.AirClearanceList .child-data-table .rt-thead.-header .rt-th:nth-child(2) {
    display: flex;
}

.CustomsAirClearanceList .rt-thead.-header .rt-th:nth-child(2),
.CustomsAirClearanceList .rt-tbody .rt-tr .rt-td.-checkbox:nth-child(2) {
    display: none;
}

.CustomsAirClearanceList .child-data-table .rt-thead.-header .rt-th:nth-child(2) {
    display: flex;
}

.react-datepicker__navigation {
    text-indent: -9000px;
}

.react-datepicker__navigation::before {
    text-indent: 1px;
}

.Milestoneslist .rt-thead.-header .rt-th:nth-child(2),
.Milestoneslist .rt-tbody .rt-tr .rt-td.-checkbox:nth-child(2) {
    display: none;
}

.Milestoneslist .child-data-table .rt-thead.-header .rt-th:nth-child(2) {
    display: flex;
}

.modal .modal-body {
    max-height: 100% !important;
}