.green {
  color: #2DBA5E; }

.red {
  color: #D62D23; }

.orange {
  color: #FF9B1E; }

.inactive {
  color: #8C8C8C; }

.black {
  color: #000; }

.light-gray-color {
  color: #C3C3C3; }

.bold {
  font-weight: 600; }

.step-indicator {
  width: 100%;
  padding: 0 15px;
  margin: 25px 0; }
  .step-indicator__progressbar {
    display: flex;
    list-style: none;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0; }
    .step-indicator__progressbar .step-indicator__step {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: center;
      position: relative; }
      .step-indicator__progressbar .step-indicator__step--icon {
        font-size: 16px;
        width: 100%;
        margin: 0 auto;
        position: relative;
        display: flex;
        justify-content: center; }
        .step-indicator__progressbar .step-indicator__step--icon i {
          background: #FFF;
          border-radius: 1234256px; }
        .step-indicator__progressbar .step-indicator__step--icon ::after {
          content: "";
          position: absolute;
          top: 7px;
          left: 0;
          width: 100%;
          transition: width 1s ease-in;
          height: 3px;
          background: #C3C3C3;
          z-index: -1; }
      .step-indicator__progressbar .step-indicator__step--details {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center; }
        .step-indicator__progressbar .step-indicator__step--details p {
          margin: 10px 0 5px 0; }
      .step-indicator__progressbar .step-indicator__step:first-child .step-indicator__step--icon {
        justify-content: center; }
        .step-indicator__progressbar .step-indicator__step:first-child .step-indicator__step--icon ::after {
          content: "";
          position: absolute;
          top: 7px;
          left: 50%;
          width: 100%;
          transition: width 1s ease-in;
          height: 3px;
          background: #C3C3C3;
          z-index: -1; }
      .step-indicator__progressbar .step-indicator__step:last-child .step-indicator__step--icon {
        justify-content: center; }
        .step-indicator__progressbar .step-indicator__step:last-child .step-indicator__step--icon ::after {
          content: "";
          position: absolute;
          top: 7px;
          left: -50%;
          width: 100%;
          transition: width 1s ease-in;
          height: 3px;
          background: #C3C3C3;
          z-index: -1; }
      .step-indicator__progressbar .step-indicator__step:only-child .step-indicator__step--icon {
        justify-content: center; }
        .step-indicator__progressbar .step-indicator__step:only-child .step-indicator__step--icon ::after {
          display: none; }
